import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VuelidateErrorExtractor from 'vuelidate-error-extractor';
import VueTheMask from '@rj-pkgs/vue-the-mask';
import { FormField, FormContext, FormSummary } from '~/components/FormField';
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VuelidateErrorExtractor, {
	i18n: false,
	messages: {
		required: 'This field is required.',
		maxLength: 'Member number must be 11 digits.',
		minLength: 'Member number must be 11 digits.',
		upperCase: 'This field must contain an uppercase letter.',
		lowerCase: 'This field must contain a lowercase letter.',
		between: 'This field must be between {min} and {max}.',
		minValue: 'This field must be at least {min}.',
		maxValue: 'This field must be no more than {max}.',
		hasNumbers: 'This field must contain a number.',
		hasAlpha: 'This field must contain a letter.',
		alpha: 'This field must be only letters.',
		alphaNum: 'This field must be only letters and numbers.',
		numeric: 'This field must be only numbers.',
		integer: 'This field must be an integer.',
		decimal: 'This field must be a decimal.',
		email: 'This field is not a valid email address.',
		ipAddress: 'This field is not a valid ip address.',
		macAddress: 'This field is not a valid mac address.',
		url: 'This field is not a valid url.',
		phone: 'This field must be a 10 digit phone number.',
		special: 'This field must contain a special character.',
		sameAs: 'This field must be the same as {eq}.',
		zip: 'This field is invalid.',
		date: 'This field is invalid.',
		notInFuture: 'This field must not be a future date.',
		atLeast18YearsAgo: 'This field must be at least 18 years ago.',
		notAVampire: 'This field must within the last 150 years.',
		color: 'Must be a valid CSS color value.',
		currency: 'This field must be a dollar amount.',
	},
	validationKeys: {},
});
Vue.component('FormField', FormField);
Vue.component('FormContext', FormContext);
Vue.component('FormSummary', FormSummary);

import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgeCouponApi } from '@trialcard/apigateway.client/edgeCouponApi';
import { EdgeCardApi } from '@trialcard/apigateway.client/edgeCardApi'
import { IActivateCardRequestModel } from '@trialcard/apigateway.models';
import { AxiosResponse } from 'axios';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {

	@Resolve
	public edgeCouponApi!: EdgeCouponApi;

	@Resolve
	public edgeCardApi!: EdgeCardApi;

    memberNumber = '';

	public async submitToAPI(request: IActivateCardRequestModel) {
		let isSuccessful = false;
		try {
			const response = await this.edgeCardApi.cardV1ActivateCard(request);
			isSuccessful = response.data?.success
		} catch(error) {
			console.error(error)
		}
		return isSuccessful;
	}

	public async checkMemberNumber(number: string) {
		let response = null
		try {
			response = await this.edgeCouponApi.memberMemberNumberStatusWithProgram({ memberId: number })
			return response
		} catch {
			response = { status: 400 } as AxiosResponse;
		}
		return response
	}
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67621d83 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages/error" */))
const _0b16c584 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages/rejection" */))
const _1a7be14e = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages/unlock" */))
const _56543438 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages/errors/400" */))
const _56380536 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages/errors/401" */))
const _55ffa732 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages/errors/403" */))
const _55e37830 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages/errors/404" */))
const _09bc2125 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages/errors/500" */))
const _239bff4d = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '#{BasePath}#/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _67621d83,
    name: "error"
  }, {
    path: "/rejection",
    component: _0b16c584,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _1a7be14e,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _56543438,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _56380536,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _55ffa732,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _55e37830,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _09bc2125,
    name: "errors-500"
  }, {
    path: "/",
    component: _239bff4d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image-file-input .v-input__icon{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#64b5f6",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-application--wrap{font-family:\"Montserrat\",sans-serif}.v-application--wrap h1.display-1,.v-application--wrap h2.display-1,.v-application--wrap h3.display-1{color:var(--color-secondary);margin-bottom:16px}.v-stepper .v-stepper__header{box-shadow:none}.v-stepper .v-stepper__header .v-stepper__label{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#64b5f6",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = ___CSS_LOADER_EXPORT___;
